import { SeoHeader } from '@components/Common';
import { GetStartedBanner, SectionSubheader } from '@components/Layouts';
import { Footer, NavigationHeader } from '@components/Navigation';
import { navigateAppStore } from '@navigation';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const AboutUs = () => {
  const renderLink = (title: string, url: string) => {
    return (
      <a href={url} target="_blank" className="underline font-bold font-sharp text-lg text-accent-green hover:text-ocean-blue">
        {title}
      </a>
    );
  };
  const handleGetAppClick = () => {
    navigateAppStore(() => {
      const element = document.getElementById('get-started-banner');
      element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    });
  };

  return (
    <>
      <NavigationHeader />

      <div className="container mx-auto mt-8">
        <section className="flex flex-col">
          <div className="flex flex-col lg:flex-row">
            <div className="w-full lg:w-7/12 md:pr-6">
              <StaticImage src="./assets/diagram.png" alt="US CAREGIVER UNIVERSE" />
            </div>

            <div className="w-full lg:w-4/12 mt-12 lg:mt-24 pt-6 px-6 md:px-12 lg:pr-0">
              <h1 className="text-4xl lg:text-6xl mb-6 mt-0">Why we do what we do</h1>
              <p className="text-lg lg:text-2xl font-sharp text-ocean-blue font-semibold">
                We put <span className="text-accent-green">53 million caregivers</span> at the center of everything we do.
                Progress and change is hard work. <br />
                <br />
                That’s why{' '}
                <span className="text-accent-green">
                  we believe in a world where technology and data can help make the act of caregiving easier for everyone
                </span>
                .
                <br />
                <br /> It’s our mission to empower each and every caregiver with the information, knowledge, resources, tips and
                tools they need to help others in their lives.
              </p>
            </div>
          </div>
        </section>
      </div>

      <section className="container mx-auto mt-16 md:mt-32 max-w-6xl">
        <div className="w-full lg:w-6/12 lg:px-4 xl:px-0">
          <div className="flex flex-row justify-end mb-10 lg:mt-0">
            <div className="w-full">
              <div className="mx-6 md:mx-12 lg:mx-0 pl-6 border-l border-l-accent-orange">
                <p className="text-lg font-medium text-ocean-blue leading-6">
                  We’re here to help you navigate your day-to-day life as a caregiver and empower you to delegate tasks so you can
                  still have time for yourself.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between px-6 md:px-12 lg:px-4 xl:px-0">
          <div className="w-full md:w-6/12 flex lg:pr-16">
            <div>
              <SectionSubheader title="Mission" />
              <h2 className="mt-0 mb-6 text-4xl lg:text-5xl">
                Empower caregivers with the tools they need to lighten
                <br />
                their load
              </h2>
              <p className="text-lg text-ocean-blue font-medium">
                No caregiver should feel alone in providing care. We provide the resources to help you give your best care, while
                still taking care of yourself.
              </p>
            </div>
          </div>
          <div className="w-full md:w-5/12 flex justify-center lg:justify-end items-start mt-6 md:mt-0">
            <StaticImage src="./assets/mission.png" alt="Empower caregivers with the tools they need to lighten" />
          </div>
        </div>
      </section>

      <section className="mt-16 md:mt-32 container mx-auto max-w-6xl">
        <div className="flex flex-col md:flex-row justify-between px-6 md:px-12 lg:px-4 xl:px-0">
          <div className="w-full md:w-6/12 flex lg:pr-16">
            <div>
              <SectionSubheader title="Our Story" />
              <h2 className="mt-0 mb-6 text-4xl lg:text-5xl">Care starts with you</h2>
              <span className="block text-accent-green text-2xl font-semibold mb-6">
                CircleOf has been helping caregivers for nearly 5 years.
              </span>
              <p className="font-medium text-lg text-ocean-blue">
                Our beginning was at{' '}
                {renderLink(
                  `Cisco's Hyper Innovation Living Lab`,
                  'https://blogs.cisco.com/innovation/introducing-two-new-startups-built-from-scratch-by-enterprise-innovation',
                )}
                . Our CEO, {renderLink('Michael Jordan', 'https://www.linkedin.com/in/notjustme/')}, was asked to solve a real
                problem with healthcare industry leaders. The challenge was to innovate and make real health impacts with a
                digital solution. We began with innovating care support for cancer patients and their families. As we grew, we
                learned a lot and we know that CARE is a special four letter word.
              </p>
            </div>
          </div>
          <div className="mt-6 lg:mt-0 w-full md:w-5/12 flex justify-center lg:justify-end items-start">
            <StaticImage src="./assets/story.png" alt="Care starts with you. Create your circle" />
          </div>
        </div>
      </section>

      <div className="container max-w-6xl mx-auto mt-16 md:mt-32 mb-16">
        <section className="flex flex-col md:flex-row justify-between px-6 md:px-12 lg:px-4 xl:px-0">
          <div className="w-full md:w-4/12">
            <SectionSubheader title="Lesson 1" />
            <h4 className="mb-0 mt-3 text-4xl lg:text-5xl">Care support technology should be for everyone </h4>
          </div>
          <div className="w-full md:w-7/12 mt-6 lg:mt-0">
            <p className="font-medium text-ocean-blue">
              From 2017 to mid 2021 CircleOf was available to people who were employed by companies or paid health insurance
              partners. That only helps a group of caregivers; not all.
              <br />
              <br />
              In the COVID pandemic, many caregivers lost their jobs and insurance OR could not afford a monthly subscription fee.
              At the same time, their caregiving duties increased. Caregivers asked that we remove all barriers. We worked to make
              that happen.
              <br />
              <br />
              Everyone deserves support and help; not just people with a job and benefits. CircleOf is FREE to everyone without
              reserving support features for a fortunate few.
            </p>
          </div>
        </section>
      </div>

      <div className="max-w-6xl mx-auto bg-neutral-gray" style={{ height: 1 }} />

      <div className="container max-w-6xl mx-auto mt-16 mb-16 md:mb-32">
        <section className="flex flex-col md:flex-row justify-between px-6 md:px-12 lg:px-4 xl:px-0">
          <div className="w-full md:w-4/12">
            <SectionSubheader title="Lesson 2" />
            <h4 className="mb-0 mt-3 text-4xl lg:text-5xl">
              Caregivers are unique; they will support others even when they are no longer actively caregiving
            </h4>
          </div>
          <div className="w-full md:w-7/12">
            <p className="font-medium text-ocean-blue mb-6 mt-6 md:mt-0">
              Caregiver fingerprints are all over our app and we are all the better because of it. Caregivers give us direct
              feedback based on their experience, wisdom and perspective. They tell us what they want, need and what they wish
              they had.
            </p>

            <span className="mb-4 font-bold text-ocean-blue block">How to get involved:</span>
            <ul className="text-ocean-blue font-medium mb-6">
              <li className="flex flex-row items-center mb-3">
                <div className="mr-4 border rounded-full flex items-center justify-center w-6 h-6">
                  <span className="font-semibold text-sm text-black">1</span>
                </div>

                <div>
                  <a
                    onClick={handleGetAppClick}
                    role="button"
                    className="font-sharp font-bold text-accent-green hover:text-ocean-blue"
                  >
                    Download CircleOf’s mobile app
                  </a>{' '}
                  and support someone who needs it
                </div>
              </li>
              <li className="flex flex-row items-center mb-3">
                <div className="mr-4 border rounded-full flex items-center justify-center w-6 h-6">
                  <span className="font-semibold text-sm text-black">2</span>
                </div>

                <div>Check out our partners, that is how we keep the app free</div>
              </li>
              <li className="flex flex-row items-center mb-3">
                <div className="mr-4 border rounded-full flex items-center justify-center w-6 h-6">
                  <span className="font-semibold text-sm text-black">3</span>
                </div>

                <div>Let us know your story and share your wisdom</div>
              </li>
              <li className="flex flex-row items-center">
                <div className="mr-4 border rounded-full flex items-center justify-center w-6 h-6">
                  <span className="font-semibold text-sm text-black">4</span>
                </div>

                <div>Reach out if you have ideas that would be helpful for other family caregivers</div>
              </li>
            </ul>

            <p className="font-medium text-ocean-blue bg-light-gray p-6 rounded-lg">
              We have an opportunity to learn from each other and help one another. CircleOf is a part of the solution and we
              partner with others on the same mission. Join us in supporting one another.
              <br />
              <br />
              <b>We work for humans who care.</b>
            </p>
          </div>
        </section>
      </div>

      <GetStartedBanner type="get-started" />

      <Footer privacyBannerColor="seafoam" />
    </>
  );
};

export default AboutUs;

export const Head = () => <SeoHeader title="About Us" />;
